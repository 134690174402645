/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import TokenService from '../services/TokenService';
import './TermsAndCondition.scss';
import Header from './Header';
import I18nS from '../services/I18nService';

function SuccessPage(): JSX.Element {
  const { t } = I18nS;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { search } = useLocation();
  const { token, partner } = TokenService.useSearchParams(search);

  useEffect(() => {
    if (token) setIsAuthenticated(true);
  }, [token, setIsAuthenticated]);
  return (
    <>
      <Header {...{ partner, isAuthenticated }} />
      <Container className="successPage-body-container">
        {isAuthenticated && (
          <Row className="successPage-body">
            <Col xs="12" className="panelHeader">
              <h1 className="sucessPage-title">{t('success_title')}</h1>
            </Col>
            <Col xs="12" className="panel">
              <span className="sucessPage-text">{t('success_text')}</span>
            </Col>
            <Col xs="12" className="close-button-container panel">
              <span className="text">{t('window_close')}</span>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default SuccessPage;
