import EnvironmentService from './EnvironmentService';
import HttpService from './HttpService';
import LogService from './LogService';

const APP_ID = 'TNC';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const logToBackEnd = async ({ userMessage = '', techMessage = '' }) => {
  const body = {
    message: `${userMessage} ${techMessage ? 'Tech-Msg: ' : ''}${techMessage}`,
    appId: APP_ID,
    level: 'error',
  };
  const url = EnvironmentService.getReporterUrl();
  if (EnvironmentService.isProduction && url) {
    HttpService.POST(url, body);
  } else {
    LogService.debug('Would report remotelly to back end with POST body:', body, url);
  }
};

export default {
  logToBackEnd,
};
