/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Store } from 'react-notifications-component';
import ReportingService from './ReportingService';
import '../component/TermsAndCondition.scss';

type ToastNotificationParams = {
  persistant?: boolean;
  techMessage?: string;
  isExpired?: boolean;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const showToastNotificationToUser = (
  message: string,
  params: ToastNotificationParams = { persistant: false, techMessage: '', isExpired: false },
) => {
  const fiveMinutesTimeout = 1000 * 60 * 5;

  const dismiss = {
    click: params.persistant,
    touch: params.persistant,
    duration: params.persistant ? fiveMinutesTimeout : 2500,
  };

  if (params.persistant) {
    ReportingService.logToBackEnd({ userMessage: message, techMessage: params.techMessage });
  }

  const promise = new Promise((resolve) => {
    const expired = params.isExpired;
    Store.addNotification({
      content: () => (
        <div className={`notification-custom-Danger ${expired ? 'expired' : ''}`}>
          <div className="mb-2 text-center">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
      ),
      container: expired ? 'top-center' : 'bottom-left',
      dismiss,
      onRemoval: (_id: string, removedBy: string) => {
        if (removedBy === 'manual') {
          resolve(false);
        } else {
          resolve(true);
        }
      },
    });
  });
  return promise;
};

const NotificationService = {
  showToastNotificationToUser,
};

export default NotificationService;
