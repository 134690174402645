import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ErrorIcon from '../assets/icons/ErrorIcon';
import './TermsAndCondition.scss';

function Modal(): JSX.Element {
  return (
    <Row className="modal-cnontent">
      <Col className="d-flex justify-content-center align-self-center" xs={12}>
        <Toast>
          <div className="d-flex justify-content-between px-2">
            <div className="d-flex  align-self-center">
              <ErrorIcon size={50} color="black" />
            </div>
            <Toast.Body className="text d-flex  align-self-center text-left">
              Für diese Angebotsnummer steht der Prozess nicht mehr zur Verfügung.
            </Toast.Body>
          </div>
        </Toast>
      </Col>
    </Row>
  );
}

export default Modal;
