declare global {
  interface Window {
    TNC_APIM_BASE_URL: string;
    APIM_SUBSCRIPTION_KEY: string;
    APIM_SUBSCRIPTION_VALUE: string;
    REPORTER_BASE_URL: string;
  }
}
const {
  REACT_APP_TNC_APIM_BASE_URL,
  REACT_APP_APIM_SUBSCRIPTION_KEY,
  REACT_APP_APIM_SUBSCRIPTION_VALUE,
  REACT_APP_REPORTER_BASE_URL,
} = process.env;

const isProduction = process.env.NODE_ENV === 'production';

const getApimBaseUrl = (): string => {
  if (isProduction) {
    return window?.TNC_APIM_BASE_URL ?? '';
  }
  return REACT_APP_TNC_APIM_BASE_URL ?? '';
};

const isOnDevOrTestStage = (): boolean => {
  const baseUrl = getApimBaseUrl();
  return !!baseUrl && (baseUrl.includes('//api.staging.aldautomotive.com') || baseUrl.includes('//localhost'));
};

const isTest = process.env.NODE_ENV === 'test';
const isDev = process.env.NODE_ENV === 'development';

const getReporterUrl = (): string => {
  if (isProduction) {
    return window.REPORTER_BASE_URL ?? '';
  }
  return REACT_APP_REPORTER_BASE_URL ?? '';
};

const getApimSubscriptionKey = (): string => {
  if (isProduction) {
    return window?.APIM_SUBSCRIPTION_KEY ?? '';
  }
  return REACT_APP_APIM_SUBSCRIPTION_KEY ?? '';
};

const getApimSubscriptionValue = (): string => {
  if (isProduction) {
    return window?.APIM_SUBSCRIPTION_VALUE ?? '';
  }
  return REACT_APP_APIM_SUBSCRIPTION_VALUE ?? '';
};

const EnvironmentService = {
  isOnDevOrTestStage,
  isTest,
  isDev,
  isProduction,
  getReporterUrl,
  getApimBaseUrl,
  getApimSubscriptionKey,
  getApimSubscriptionValue,
};

export default EnvironmentService;
