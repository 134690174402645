import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import SuccessPage from './component/SuccessPage';
import TermsAndCondition from './component/TermsAndCondition';
import StoryBook from './component/StoryBook';
import EnvironmentService from './services/EnvironmentService';
import NotFoundPage from './component/NotFoundPage';

function App(): JSX.Element {
  return (
    <div className="App" data-testid="app">
      <Router>
        <Routes>
          <Route path="/" element={<TermsAndCondition />} />
          <Route path="/success" element={<SuccessPage />} />
          {EnvironmentService.isOnDevOrTestStage() && <Route path="/storybook" element={<StoryBook />} />}
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
