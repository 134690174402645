/* eslint-disable react/no-danger */
import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ApiService from '../services/ApiService';
import LogService from '../services/LogService';
import TokenService from '../services/TokenService';
import './TermsAndCondition.scss';
import Header from './Header';
import NotificationService from '../services/NotificationService';
import Modal from './Modal';
import I18nS from '../services/I18nService';

const useValidation = (): [(response: boolean) => void, boolean | undefined] => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>();
  const confirmationResponse = (response: boolean): void => {
    setIsConfirmed(response);
  };
  return [confirmationResponse, isConfirmed];
};

function TermsAndCondition(): JSX.Element {
  const { t } = I18nS;
  const { search } = useLocation();
  const { partner } = TokenService.useSearchParams(search);
  const [webIdConfirmation, setWebIdConfirmation] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [confirmationResponse, isConfirmed] = useValidation();
  const { token, emailAddress, transactionId } = TokenService.useSearchParams(search);
  const [isExpired, setIsExpired] = useState(false);
  const [isTransactionUnkown, setIsTransactionUnkown] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setWebIdConfirmation(event.target.checked);
  };

  const { hasExpired } = ApiService;

  LogService.log('TermsAndCondition', { isTransactionUnkown });

  useEffect(() => {
    if (isExpired) {
      NotificationService.showToastNotificationToUser(
        'Die Gültigkeit des Leasingantrages ist abgelaufen. <br /> Bitte kontaktieren Sie Ihren Händler.',
        { persistant: true, techMessage: '', isExpired },
      );
    }
  }, [isExpired, isTransactionUnkown]);

  useEffect(() => {
    if (!emailAddress || !transactionId) return;
    ApiService.checkWebIdTnc({ emailAddress, transactionId, confirmationResponse });
    hasExpired({ transactionId, emailAddress, setIsExpired, setIsTransactionUnkown, confirmationResponse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, transactionId, hasExpired]);

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]);

  const redirectToSuccessPage = (): void => {
    if (isConfirmed !== true) ApiService.postWebIdTnc({ emailAddress, transactionId, token });
    LogService.log('RedirectToSuccessPage');
  };

  return (
    <>
      <ReactNotifications />
      <Header {...{ partner, isAuthenticated }} />
      <Container className="terms-and-condition" data-testid="terms-and-condition" fluid>
        {isAuthenticated && (
          <>
            <div data-testid="isAuthenticated" className="panelHeader">
              {isTransactionUnkown && <Modal />}
              {!isTransactionUnkown && (
                <h1 className="tnc-title">{isConfirmed ? t('isConfirmed_title') : t('notConfirmed_title')}</h1>
              )}
            </div>
            <div className="terms-and-condition-body panel">
              <div className="check-box" data-testid={`webIdConfirmation_${webIdConfirmation}`}>
                <Checkbox
                  disabled={isExpired || isConfirmed || isTransactionUnkown}
                  checked={webIdConfirmation}
                  onChange={handleChange}
                  name="webIdConfirmation"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div className="content-body-text-container" data-testid="tnc_text">
                <span className="text" dangerouslySetInnerHTML={{ __html: I18nS.t('acceptTermsAndConditions') }} />
              </div>
            </div>
            <div className="close-button-container panel">
              {isConfirmed ? (
                <span data-testid={`isConfirmed_${isConfirmed}`} className="text">
                  {t('window_close')}
                </span>
              ) : (
                <button
                  data-testid="isNotConfirmed"
                  onClick={redirectToSuccessPage}
                  disabled={!webIdConfirmation}
                  type="submit"
                  className="close-button"
                >
                  <span className={webIdConfirmation ? 'close-button-text-checked' : 'close-button-text'}>
                    {t('confirmed_Button')}
                  </span>
                </button>
              )}
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default TermsAndCondition;
