import { ReactNotifications } from 'react-notifications-component';
import { Button } from 'react-bootstrap';
import Header, { logos } from './Header';
import NotificationService from '../services/NotificationService';

function StoryBook(): JSX.Element {
  const errorContext = { message: 'Resource not found', statusCode: 404 };
  return (
    <div>
      <h1>StoryBook</h1>
      <ReactNotifications />
      {Object.keys(logos).map((partner) => {
        const isAuthenticated = true;
        return <Header {...{ partner, isAuthenticated }} key={partner} />;
      })}
      <br />
      <br />
      <br />
      <br />
      <h2> Toast Messages </h2>
      <Button
        onClick={() =>
          NotificationService.showToastNotificationToUser('Persistant Msg, <br /> My Error as second line', {
            persistant: true,
            techMessage: JSON.stringify({ error: 'My Error', bla: 'bla', blub: 'blub' }),
          })
        }
      >
        Persistent Toast
      </Button>
      <Button
        onClick={() =>
          NotificationService.showToastNotificationToUser(JSON.stringify(errorContext), {
            persistant: true,
            techMessage: JSON.stringify({ ...errorContext, error: '500 Error occured' }),
          })
        }
      >
        Normal Toast
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
}

export default StoryBook;
