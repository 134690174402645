import Navbar from 'react-bootstrap/Navbar';
import ald from '../assets/logos/co-branded Logo.png';
import ford from '../assets/logos/ford_logo.png';
import hyundai from '../assets/logos/hyundai_logo.png';
import jaguar from '../assets/logos/jaguar_logo.png';
import kia from '../assets/logos/kia_logo.png';
import landrover from '../assets/logos/landrover_headline_logo.png';
import nissan from '../assets/logos/nissan_logo.png';
import polestar from '../assets/logos/polestar_logo.png';
import renault from '../assets/logos/renault_logo.png';
import version from '../currentAppVersion.json';
import './TermsAndCondition.scss';

export const logos: Record<string, string> = {
  hyundai,
  kia,
  ford,
  nissan,
  renault,
  landrover,
  polestar,
  jaguar,
  partnervertrieb: ald,
  partnerv: ald,
  ald,
};

interface HeaderProps {
  partner: string | null;
  isAuthenticated: boolean;
}

function Header(props: HeaderProps): JSX.Element {
  const { partner, isAuthenticated } = props;
  const partnerKey = partner ? partner.toLowerCase() : 'ald';
  const partnerLogo = logos[partnerKey.split('-')[0]] || logos.ald;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isAuthenticated && (
        <Navbar className="header">
          <Navbar.Brand href="#home">
            <img className="img-fluid w-100 h-100" src={partnerLogo} alt="partner-logo" />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <div className="header__links">
              <span className="version">Release:{version.releaseDate}</span>
            </div>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
}

export default Header;
