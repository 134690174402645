import { AxiosError, AxiosResponse } from 'axios';
import LogService from './LogService';
import EnvironmentService from './EnvironmentService';
import HttpService from './HttpService';
import NotificationService from './NotificationService';

const checkWebIdTnc = async ({
  transactionId,
  emailAddress,
  confirmationResponse,
}: {
  transactionId: string | null;
  emailAddress: string | null;
  confirmationResponse: (response: boolean) => void;
}): Promise<AxiosResponse | undefined> => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid-tnc/${transactionId}/${emailAddress}`;
  try {
    const response = await HttpService.GET(url);
    if (response.status === 200 && response.data.termsAndConditionsConfirmed === true) {
      confirmationResponse(true);
      return response.data;
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response && error.response.status === 404) {
        confirmationResponse(false);
      }
    }
    LogService.error(error);
  }
  return undefined;
};

const postWebIdTnc = async ({
  transactionId,
  emailAddress,
  token,
}: {
  transactionId: string | null;
  emailAddress: string | null;
  token: string | null;
}): Promise<AxiosResponse | undefined> => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid-tnc/${transactionId}/${emailAddress}`;
  try {
    const response = await HttpService.POST(url, {});
    if (response) {
      const uri = `/success?token=${token}`;
      window.location.href = uri;
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorContext = { transactionId, emailAddress, error: error?.response?.status, url };
      const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
      const techMessage = `${error.message} for emailAddress=${emailAddress} with transactionId=${transactionId}`;
      await NotificationService.showToastNotificationToUser(errorMessage, {
        persistant: true,
        techMessage: JSON.stringify(errorContext) + techMessage,
      });
    }
    LogService.error(error);
  }
  return undefined;
};

const hasExpired = async ({
  transactionId,
  emailAddress,
  setIsExpired,
  setIsTransactionUnkown,
  confirmationResponse,
}: {
  transactionId: string | null;
  emailAddress: string | null;
  setIsExpired: (isExpired: boolean) => void;
  setIsTransactionUnkown: (isTransactionUnkown: boolean) => void;
  confirmationResponse: (response: boolean) => void;
}): Promise<AxiosResponse | undefined> => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid-tnc/${transactionId}/${emailAddress}`;
  try {
    const response = await HttpService.GET(url);
    LogService.log('hasExpired', { response });
    if (response.status === 200) {
      setIsExpired(false);
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        LogService.error('error.response=======', error.response);
        const isExpired = error.response.data.status === 410 && error.response.data.message === 'EXPIRED';
        const isTransactionUnknown =
          error.response.data.status === 404 && error.response.data.message === 'Transcation ID unknown';
        const isExecuted = error.response.data.status === 405 && error.response.data.message === 'EXECUTED';
        if (isExpired) {
          setIsExpired(true);
        }

        if (isTransactionUnknown) {
          setIsTransactionUnkown(true);
        }

        if (isExecuted) {
          confirmationResponse(true);
        }
      } else {
        LogService.error('hasExpired=======', error);
      }
    }
  }
  return undefined;
};

const ApiService = {
  checkWebIdTnc,
  postWebIdTnc,
  hasExpired,
};

export default ApiService;
