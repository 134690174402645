type TranslationKey =
  | 'isConfirmed_title'
  | 'notConfirmed_title'
  | 'acceptTermsAndConditions'
  | 'confirmed_Button'
  | 'window_close'
  | 'success_title'
  | 'success_text';

/* eslint-disable camelcase */
const EN: Record<TranslationKey, string> = {
  isConfirmed_title: 'Danke, die AGB der WebId Solutions GmbH wurde bereits bestätigt.',
  confirmed_Button: 'AGB bestätigen',
  window_close: 'Sie können dieses Browserfenster jetzt schließen.',
  notConfirmed_title: 'Bitte bestätige die AGB der WebID Solutions GmbH.',
  acceptTermsAndConditions:
    'Ich akzeptiere die Allgemeinen Geschäftsbedingungen <a href="https://webid-solutions.de/agb/" target="_blank" rel="noreferrer"> (AGB)</a> der WebID Solutions GmbH. Ausführliche Informationen zum Umgang mit Ihren personenbezogenen Daten durch die WebID Solutions GmbH können Sie der WebID Datenschutzerklärung entnehmen. <a href="https://webid-solutions.de/datenschutzbestimmungen/" target="_blank" rel="noreferrer">(Datenschutzerklärung)',
  success_title: 'Vielen Dank für die Bestätigung der AGB der WebID Solutions GmbH.',
  success_text: 'In Kürze erhalten Sie von WebID Solutions GmbH den Einladungslink zum digitalen Vertragsabschluss.',
};
const DE: Record<TranslationKey, string> = {
  isConfirmed_title: 'Danke, die AGB der WebId Solutions GmbH wurde bereits bestätigt.',
  confirmed_Button: 'AGB bestätigen',
  window_close: 'Sie können dieses Browserfenster jetzt schließen.',
  notConfirmed_title: 'Bitte bestätige die AGB der WebID Solutions GmbH.',
  acceptTermsAndConditions:
    'Ich akzeptiere die Allgemeinen Geschäftsbedingungen <a href="https://webid-solutions.de/agb/" target="_blank" rel="noreferrer"> (AGB)</a> der WebID Solutions GmbH. Ausführliche Informationen zum Umgang mit Ihren personenbezogenen Daten durch die WebID Solutions GmbH können Sie der WebID Datenschutzerklärung entnehmen. <a href="https://webid-solutions.de/datenschutzbestimmungen/" target="_blank" rel="noreferrer">(Datenschutzerklärung)',
  success_title: 'Vielen Dank für die Bestätigung der AGB der WebID Solutions GmbH.',
  success_text: 'In Kürze erhalten Sie von WebID Solutions GmbH den Einladungslink zum digitalen Vertragsabschluss.',
};
const translationsForLocale = (locale: 'en' | 'de'): Record<TranslationKey, string> => {
  if (locale === 'de') {
    return DE;
  }
  return EN;
};
// Use this method only for testing purposes! never use it in production code.
export const fullTranslate = (key: TranslationKey): string => translationsForLocale('de')[key] || key;
const isTest: boolean = process.env.NODE_ENV === 'test';
const t = (key: TranslationKey): string => {
  if (isTest) {
    return key;
  }
  return fullTranslate(key);
};
const tWithParams = (key: TranslationKey, params: Record<string, string> = {}): string => {
  const template = t(key);
  return template
    ? Object.keys(params).reduce((result, attr) => result.replace(`{${attr}}`, params[attr]), template)
    : '';
};

const I18nS = {
  t,
  tWithParams,
};
export default I18nS;
