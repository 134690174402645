/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

interface DecodedParams {
  token: string | null;
  partner: string | null;
  emailAddress: string | null;
  transactionId: string | null;
}
interface DecodedToken {
  aud: string;
  emailAddress: string;
  exp: number;
  iat: number;
  iss: string;
  partner: string;
  sub: string;
  transactionId: string;
}

const useSearchParams = (search: string): DecodedParams => {
  const searchParams = new URLSearchParams(search);
  const [token, setToken] = useState<string | null>(null);
  const [partner, setPartner] = useState<string | null>(null);
  const [emailAddress, setEmailAddress] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string | null>(null);

  useEffect(() => {
    const newToken = searchParams.get('token');
    const decoded: DecodedToken | null = newToken ? jwtDecode(newToken as string) : null;
    if (newToken) {
      setToken(newToken);
    }
    if (decoded && decoded.partner && decoded.emailAddress && decoded.transactionId) {
      setPartner(decoded.partner);
      setEmailAddress(decoded.emailAddress);
      setTransactionId(decoded.transactionId);
    }
  }, [searchParams]);

  return { token, partner, emailAddress, transactionId };
};

const TokenService = {
  useSearchParams,
};

export default TokenService;
