/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import EnvironmentService from './EnvironmentService';

const createHeadersForRequest = (): Record<string, string> => ({
  [EnvironmentService.getApimSubscriptionKey()]: EnvironmentService.getApimSubscriptionValue(),
  'Cache-Control': 'no-cache',
});

function GET(url: string, responseType?: ResponseType): Promise<AxiosResponse<any, any>> {
  const config: AxiosRequestConfig = {
    headers: { ...createHeadersForRequest() },
    responseType,
  };
  return axios.get(url, config);
}

function POST<T>(url: string, body: T, config?: AxiosRequestConfig): Promise<AxiosResponse<any, any>> {
  const headers = { ...createHeadersForRequest(), ...(config?.headers || {}) };
  return axios.post<T>(url, body, { headers, ...config });
}

const HttpService = {
  GET,
  POST,
};

export default HttpService;
